<template>
    <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16' 
    :style='minHeightStyle'>
    <div
      class='background-style text-white py-4 px-4 lg:py-16 lg:px-8 flex-shrink-0'
      :style='`${sidebarBgStyle}`'>
      <ul class='text-xs sidebar-navigation-links flex flex-col lg:sticky lg:top-24'>
        <li v-for='(sponsorsByLevel, index) in sponsorList'
          :key='`sidebar-link-${sponsorsByLevel.levelName}-${index}`'
          class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'
          v-scroll-to="`#${sponsorsByLevel.levelName}`">
          <span
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            {{ sponsorsByLevel.levelName }}
          </span>
        </li>
        <li class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link
            :to='{ name: "FloorPlanBooths" }'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            부스 배치도
          </router-link>
        </li>
      </ul>
    </div>
    <div class='py-8 lg:py-16 flex-grow relative px-4 lg:px-0'>
      <h1 class='text-3xl font-bold mb-4 lg:mb-8'>후원사</h1>
      <sponsors-by-level v-for='(sponsorsByLevel, index) in sponsorList'
        :key='`sponsors-by-level-${sponsorsByLevel.levelName}`'
        :ref='`sponsor-list-container-${index}`'
        :sponsors='sponsorsByLevel'
        :configurations='configurationsByLevel(index)'
        :sizing-multiplier='sponsorsConfigurations.sizingMultiplier'
        :full-width-background='sponsorsConfigurations.fullWidthBackground'
        :id='sponsorsByLevel.levelName'
        class='px-2 lg:px-4' />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions }  from 'vuex'
import SponsorsByLevel from '@/components/sponsors/SponsorsByLevel.vue'

export default {
  name: 'Sponsors',
  components: {
    SponsorsByLevel,
  },
  props: {
    requireLogin: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('sponsors', [
      'sponsorList',
    ]),
    ...mapState('events', [
      'sponsorsConfigurations',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    customBackgroundStyle () {
      return "background-color: #926699; background-image: url(https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kossoasc/sidebar_image_v2.png); background-repeat: no-repeat; background-position: top; background-size: 100% auto;"
    },
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
  },
  methods: {
    ...mapActions([
      'hideFullpageLoadingIndicator',
      'showFullpageLoadingIndicator',
    ]),
    ...mapActions('sponsors', [
      'getSponsors',
      'getSponsorsNoAuth',
    ]),
    configurationsByLevel (levelNumber) {
      return this.sponsorsConfigurations.levels.find(level => level.levelIndex === (levelNumber+1))
    },
  },
  beforeDestroy () {
    this.hideFullpageLoadingIndicator()
  },
  mounted () {
    this.showFullpageLoadingIndicator()
    if (this.requireLogin) {
      this.getSponsors('Sponsors').then(() => {
        this.hideFullpageLoadingIndicator()
      })
    } else {
      this.getSponsorsNoAuth('Sponsors').then(() => {
        this.hideFullpageLoadingIndicator()
      })
    }

  },
}
</script>

<style lang='scss' scoped>

.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold border rounded-md;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>

